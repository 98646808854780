<template>
  <v-app>
    <Header></Header>
    <h1 style="text-align: center;color: red">Thank you</h1><br>
    <h2 style="text-align: center;">Our Executive will get in touch within 48 hours.</h2><br>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Footer from "@/components/footer";
import Header from "@/components/header";
export default {
  name: "thanks",
  components: {Header, Footer},
  mounted() {
    window.gtag('event', 'conversion', {'send_to': 'AW-459468057/SOPlCL_dgIwCEJnai9sB'});
  }
}
</script>

<!-- Event snippet for Submit lead form conversion page -->


<style scoped>

</style>